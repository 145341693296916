@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  scroll-behavior: smooth;
}

/* Custom scrollbar using CSS */
/* Width and height for the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the tracking area */
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #61a78b; /* Color of the scroll thumb */
  border-radius: 5px; /* Rounded corners for the scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4d8870; /* Color of the scroll thumb on hover */
}

#condition-container,
#testimonial-container {
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0; /* Width of the vertical scrollbar */
  height: 0; /* Height of the horizontal scrollbar */
}
