// navigation
.index-navigation {
  @apply w-screen fixed z-10;
  @apply shadow-lg;

  background-color: #90c6b1;

  .index-navigation-container {
    @apply flex gap-4 items-center justify-end;
    @apply max-w-screen-xl m-auto px-8 py-2;

    .logo {
      @apply w-16 h-16 mr-auto;
      @apply object-contain cursor-pointer;
    }

    .links-container {
      @apply hidden lg:flex lg:gap-4;
      @apply flex-1;
      @apply font-bold text-lg;
      @apply text-white;

      .link {
        @apply cursor-pointer no-underline;
      }
    }

    .extras-container {
      @apply hidden lg:flex lg:gap-2;

      img {
        @apply h-10 px-4;
        @apply cursor-pointer object-contain rounded-full;
        @apply bg-white;
      }
    }

    .dropdown-container {
      @apply relative;

      button {
        @apply inline-flex items-center;
        @apply px-5 py-2.5;
        @apply rounded-lg;
        @apply font-medium text-sm text-center uppercase;
        @apply bg-white;
      }

      .dropdown-links-container {
        @apply w-44 z-10 absolute bottom-0 right-0 translate-y-full;
        @apply divide-y rounded-lg shadow;
        @apply bg-white divide-gray-100;

        ul {
          @apply py-2 text-sm text-gray-700;

          li {
            @apply cursor-pointer;
            @apply block mx-2 px-4 py-2;
          }
        }
      }
    }

    .navigation-drawer-toggle-icon {
      @apply lg:hidden;
      @apply cursor-pointer select-none;
      @apply text-5xl;
    }
  }

  .navigation-drawer {
    @apply flex flex-col gap-8 lg:hidden;
    @apply w-screen h-screen p-4 fixed top-0 left-0 z-20;
    @apply transition-transform;
    @apply bg-black text-white;

    .drawer-actions-container {
      @apply flex justify-end;
      @apply text-2xl;

      .drawer-close-icon {
        @apply cursor-pointer;
      }
    }

    .drawer-links-container {
      @apply flex flex-col;

      div {
        @apply py-2;
      }
    }

    .extras-containersss {
      @apply flex gap-4 justify-between;

      img {
        @apply h-8 px-4;
        @apply cursor-pointer object-contain rounded-full;
        @apply bg-white;
      }
    }
  }
}

// welcome block
.welcome-block {
  @apply lg:h-screen;

  .content-container {
    @apply lg:h-full;
    @apply bg-white bg-opacity-70 lg:bg-gradient-to-l lg:from-white lg:from-40% lg:bg-opacity-100 lg:bg-transparent;

    .portions-container {
      @apply lg:flex lg:items-center;
      @apply max-w-screen-xl m-auto p-8 lg:h-full;

      .left-portion {
        @apply flex-1;
      }

      .right-portion {
        @apply flex flex-col;
        @apply flex-1;

        .title {
          @apply max-w-64 mx-auto lg:max-w-xl lg:m-0;
          @apply font-bold text-3xl text-center lg:text-5xl lg:text-left;
        }

        .sub-title {
          @apply max-w-64 mx-auto mt-2 lg:max-w-none lg:m-0 lg:mt-2;
          @apply text-xl text-center lg:text-4xl lg:text-left;
        }

        .description {
          @apply max-w-96 mx-auto my-8 lg:max-w-none;
          @apply text-center lg:text-left;
        }

        button {
          @apply me-2 mb-2 px-8 py-4 self-center;
          @apply cursor-pointer rounded-full;
          @apply font-medium text-sm text-center lg:text-xl;
          @apply text-white;
        }
      }
    }
  }
}

// whats new
.whats-new-block {
  @apply p-8 lg:p-16;

  .portions-container {
    @apply max-w-screen-xl m-auto;

    .portions-bg {
      @apply flex flex-col-reverse lg:flex-row gap-4;
      @apply lg:p-4;

      .left-portion {
        @apply p-2 flex-1;
        @apply bg-blue-600;

        .holder {
          @apply flex flex-col gap-4;
          @apply border-4 lg:border-r-0;
          @apply bg-blue-600;
          @apply p-2;

          .title {
            @apply font-bold text-2xl text-center lg:text-5xl lg:text-left;
            @apply text-white;
          }
          .sub-title {
            @apply font-semibold text-xl text-center lg:text-3xl lg:text-left;
            @apply text-white;
          }
          .description {
            @apply whitespace-pre-line;
            @apply text-white;
          }
        }
      }

      .right-portion {
        @apply flex-1;
        @apply border-4 border-b-0 lg:border-0;
      }
    }
  }
}

// services home
.services-home-block {
  @apply p-8 lg:p-16;

  .content-container {
    @apply flex flex-col gap-4 items-center;
    @apply max-w-screen-xl m-auto;

    .title {
      @apply font-bold text-3xl lg:text-5xl;
    }

    .description {
      @apply text-center lg:text-xl;
    }

    .featured-container {
      @apply grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8;

      .featured-service {
        @apply flex flex-col gap-6 lg:flex-row;
        @apply p-4;
        @apply rounded-2xl shadow-2xl;
        @apply hover:bg-gray-300;

        .left-portion {
          @apply lg:m-auto lg:mx-8;

          .image {
            @apply w-28 h-28 m-auto;
            @apply rounded-full;
          }
        }

        .right-portion {
          @apply flex flex-col gap-4 items-center;

          .title {
            @apply font-bold text-xl text-center;
          }

          .description {
            @apply text-base text-center;
          }

          a {
            @apply font-bold underline;
          }
        }
      }
    }
  }
}

// info 1
.info-1-block {
  @apply p-8 lg:p-16;

  .portions-container {
    @apply flex flex-col gap-4 lg:flex-row-reverse lg:items-center;
    @apply max-w-screen-xl m-auto;

    .left-portion {
      @apply lg:min-w-96;

      .image {
        @apply w-full aspect-[12/16];
        @apply rounded-2xl shadow-lg;
      }
    }

    .right-portion {
      @apply flex flex-col gap-4 lg:gap-6;

      .title {
        @apply text-white font-bold text-2xl text-center lg:text-4xl lg:text-left;
      }

      .white-bar {
        @apply border-8 border-white lg:max-w-40;
      }

      .description {
        @apply text-white text-center lg:text-lg lg:text-left;
      }

      button {
        @apply me-2 mb-2 px-8 py-4 lg:mr-auto;
        @apply cursor-pointer rounded-full;
        @apply font-medium text-sm text-center lg:text-xl;
        @apply bg-white;
      }
    }
  }
}

// doctors
.doctors-block {
  @apply flex flex-col gap-4 lg:hidden;
  @apply p-8 lg:p-16;

  .image {
    @apply w-full aspect-[4/3];
    @apply rounded-2xl shadow-lg;
  }

  .title {
    @apply italic text-lg;
  }

  .sub-title {
    @apply font-bold text-2xl;
  }

  .description {
    @apply whitespace-pre-line;
  }

  button {
    @apply me-2 mb-2 px-8 py-4;
    @apply cursor-pointer rounded-full;
    @apply font-medium text-sm text-center lg:text-xl;
    @apply text-white;
  }
}

.doctors-block-large {
  @apply hidden lg:block;

  .fader {
    @apply flex flex-col;
    @apply w-full h-full p-8 lg:py-32;
  }

  .portions-container {
    @apply flex;
    @apply max-w-screen-xl m-auto;

    .left-portion {
      @apply flex-1;
    }

    .right-portion {
      @apply flex flex-col gap-2;
      @apply flex-1;

      .title {
        @apply italic text-2xl;
      }

      .sub-title {
        @apply font-bold text-5xl;
      }

      .description {
        @apply my-6;
        @apply whitespace-pre-line;
      }

      button {
        @apply me-2 mb-2 px-8 py-4 self-start;
        @apply cursor-pointer rounded-full;
        @apply font-medium text-sm text-center lg:text-xl;
        @apply text-white;
      }
    }
  }
}

// conditions home
.conditions-home-block {
  @apply p-8 lg:p-16;

  .content-container {
    @apply flex flex-col gap-6;
    @apply max-w-screen-xl m-auto;

    .title {
      @apply font-bold text-2xl text-center lg:text-5xl;
    }

    .description {
      @apply text-center lg:text-xl;
    }

    .items-container {
      @apply w-full px-4 relative;

      .items-wrapper {
        @apply flex gap-4;
        @apply w-full pb-4;
        @apply overflow-auto;

        .item {
          @apply min-w-full aspect-[4/3] lg:min-w-80;
          @apply rounded-2xl shadow-sm overflow-hidden;

          .fader {
            @apply flex flex-col justify-end;
            @apply h-full p-4;

            .title {
              @apply text-white text-lg text-left lg:text-3xl;
            }

            a {
              @apply underline;
              @apply text-white;
            }
          }
        }
      }

      .actions-container {
        @apply flex justify-between;
        @apply w-full top-1/2 left-0 absolute;

        .action-icon {
          @apply cursor-pointer select-none;
        }
      }
    }
  }
}
