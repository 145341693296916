.admin-login-form {
  @apply max-w-sm mx-auto my-16 p-8;
  @apply rounded-xl shadow-xl;
  @apply bg-white;

  img {
    @apply w-56 m-auto;
  }

  .inputs-container {
    @apply flex flex-col gap-4;

    label {
      @apply block mb-2 text-sm font-medium text-gray-900;
    }

    input {
      @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
    }
  }

  .buttons-container {
    @apply flex gap-4 justify-center;

    button {
      @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto mt-8 px-5 py-2.5 text-center;
    }
  }
}
